exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-audits-tsx": () => import("./../../../src/pages/audits.tsx" /* webpackChunkName: "component---src-pages-audits-tsx" */),
  "component---src-pages-bazel-tsx": () => import("./../../../src/pages/bazel.tsx" /* webpackChunkName: "component---src-pages-bazel-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-case-study-pfizer-tsx": () => import("./../../../src/pages/case-study/pfizer.tsx" /* webpackChunkName: "component---src-pages-case-study-pfizer-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-groups-tsx": () => import("./../../../src/pages/groups.tsx" /* webpackChunkName: "component---src-pages-groups-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-opensource-tsx": () => import("./../../../src/pages/opensource.tsx" /* webpackChunkName: "component---src-pages-opensource-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog-tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-cvs-all-cvs-tsx": () => import("./../../../src/templates/cvs/allCvs.tsx" /* webpackChunkName: "component---src-templates-cvs-all-cvs-tsx" */),
  "component---src-templates-cvs-template-1-js": () => import("./../../../src/templates/cvs/template-1.js" /* webpackChunkName: "component---src-templates-cvs-template-1-js" */),
  "component---src-templates-cvs-template-anonymous-tsx": () => import("./../../../src/templates/cvs/template-anonymous.tsx" /* webpackChunkName: "component---src-templates-cvs-template-anonymous-tsx" */),
  "component---src-templates-group-tsx": () => import("./../../../src/templates/group.tsx" /* webpackChunkName: "component---src-templates-group-tsx" */)
}

