import { ThemeUIContextValue, useThemeUI } from "theme-ui"

const theme = {
  breakpoints: [`768px`, `1024px`, `1500px`],
  colors: {
    text: `black`,
    background: `white`,
    beige: `#f5cfb1`,
    blue: `#5373cf`,
    green: `#55c171`,
    grey: `#dad9d7`,
    greyLight: `#f0f0f0`,
    orange: `#fc7851`,
    purple: `#4d22a8`,
    red: `#ff3b5a`,
    yellow: `#fee080`,
  },
  fonts: {
    heading: `stratos, Arial, Helvetica, sans-serif`,
    body: `stratos, Arial, Helvetica, sans-serif`,
  },
  fontSizes: [
    `14px`, // 0
    `16px`, // 1
    `18px`, // 2
    `24px`, // 3
    `27px`, // 4
    `34px`, // 5
    `42px`, // 6
    `46px`, // 7
    `66px`, // 8
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {},
  space: [0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72],
  styles: {
    root: {
      a: {
        textDecoration: `none`,
      },
    },
  },
  text: {
    default: {
      fontFamily: `body`,
    },
  },
}

interface ExactContextValue extends Omit<ThemeUIContextValue, `theme`> {
  theme: typeof theme
}

export const useTheme = useThemeUI as unknown as () => ExactContextValue

export default theme
